// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE} from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared/shared.module';
// import { NgxCurrencyModule } from 'ngx-currency';

// Providers
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { LazyLoadImgDirective } from './core/directives/lazy-load-img.directive';

// Componentes
import { AppComponent } from './app.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { EscaneoComponent } from './components/escaneo/escaneo.component';
import { HeaderComponent } from './components/header/header.component';
import { ErrorComponent } from './pages/error/error.component';
import { ModalComponent } from './components/modal/modal.component';
import { ComparacionPlanesComponent } from './pages/comparacion-planes/comparacion-planes.component';
import { ServicioClienteComponent } from './pages/servicio-cliente/servicio-cliente.component';
import { PoliticasPrivacidadComponent } from './pages/politicas-privacidad/politicas-privacidad.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { ClausulaPolizaComponent } from './pages/clausula-poliza/clausula-poliza.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactanosComponent } from './pages/contactanos/contactanos.component';
import { WebcheckoutComponent } from './pages/webcheckout/webcheckout.component';
import { WebcheckoutConfirmComponent } from './pages/webcheckout-confirm/webcheckout-confirm.component';
import { SuscripcionComponent } from './components/suscripcion/suscripcion.component';
import { BlogComponent } from './pages/blog/blog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChatDatinaComponent } from './components/chat-datina/chat-datina.component';
import { BannersComponent } from './components/banners/banners.component';
import { PadAppComponent } from './pages/landings/pad-app/pad-app.component';
import { AutorizacionComponent } from './pages/autorizacion/autorizacion.component';
import { AutorizacionOpcionalComponent } from './pages/autorizacion-opcional/autorizacion-opcional.component';
import { HomeComponent } from './pages/home/home.component';

import { FraudeComponent } from './pages/landings/fraude/fraude.component';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    // CapitalizePipe, VALIDAR**
    InicioComponent,
    EscaneoComponent,
    HeaderComponent,
    ErrorComponent,
    ModalComponent,
    ComparacionPlanesComponent,
    ServicioClienteComponent,
    PoliticasPrivacidadComponent,
    TerminosCondicionesComponent,
    ClausulaPolizaComponent,
    FaqComponent,
    ContactanosComponent,
    WebcheckoutComponent,
    WebcheckoutConfirmComponent,
    SuscripcionComponent,
    BlogComponent,
    FooterComponent,
    ChatDatinaComponent,
    BannersComponent,
    PadAppComponent,
    AutorizacionComponent,
    AutorizacionOpcionalComponent,
    LazyLoadImgDirective,
    FraudeComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaV3Module,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    CookieService,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf-c0gaAAAAAOMDQJ9e35rrwTb8FeQ0t3rl3rTn' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  entryComponents: [
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
