import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { MicroLoaderComponent } from 'src/app/components/micro-loader/micro-loader.component';
import { PublicidadProductosComponent } from 'src/app/components/publicidad-productos/publicidad-productos.component';
import { ScoreComponent } from 'src/app/components/score/score.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { TarjetaPlanComponent } from 'src/app/components/tarjeta-plan/tarjeta-plan.component';
import { DashPadModuleComponent } from 'src/app/components/dashboard/dash-pad-module/dash-pad-module.component';
import { BoxDebtComponent } from 'src/app/components/dashboard/dash-pad-module/box-debt/box-debt.component';
import { InfoProductComponent } from 'src/app/components/home/info-product/info-product.component';
import { TestimonialsComponent } from 'src/app/components/home/testimonials/testimonials.component';
import { SponsorsComponent } from 'src/app/components/home/sponsors/sponsors.component';
import { ViewPostsComponent } from 'src/app/components/home/view-posts/view-posts.component';

@NgModule({
  declarations: [
    LoadingComponent,
    MicroLoaderComponent,
    PublicidadProductosComponent,
    ScoreComponent,
    SidebarComponent,
    TarjetaPlanComponent,
    DashPadModuleComponent,
    BoxDebtComponent,
    InfoProductComponent,
    TestimonialsComponent,
    SponsorsComponent,
    ViewPostsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingComponent,
    MicroLoaderComponent,
    PublicidadProductosComponent,
    ScoreComponent,
    SidebarComponent,
    TarjetaPlanComponent,
    DashPadModuleComponent,
    BoxDebtComponent,
    InfoProductComponent,
    TestimonialsComponent,
    SponsorsComponent,
    ViewPostsComponent
  ]
})
export class SharedModule { }
