import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoadingService } from './loading.service';
import { BrokerService } from './broker.service';

declare var $: any;
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  imgLateral: any;
  imgMobile: any;
  titulo: any;
  textoHTML: any;
  textBoton: any;
  enlace: any;
  enlaceBoton: any;
  tipo: any;
  enlaceExterno: any;
  closeB: any;

  email: any;
  anonimo: any = null;
  cuenta: any;

  txtDefault = true;
  txtAprroved = false;

  public modalAdverOpen = false;

  public okSendSMS = true;
  public funnModal = '';

  //Variables para ponte al diaYA
  public itemPadYa:number = 0;
  public urlPadYa:string = '';
  public numberPadYa:string = '';
  public ctaPadYa:string = 'CTA';
  public paymentInstructionsPadYA:Array<string> = ['Puedes comunicarte con los canales autorizados del aliado:','Dirigirte a la pagina web del aliado y comenzar el proceso de negociación y pago.','Dirigirte a la pagina web del aliado y comenzar el proceso de negociación y pago.'];
  public consumerNumber:string = '';
  public estadotexto:string = '';
  public saldoMora:string = '';
  public numeroCuenta:string = '';
  
  constructor(private loading: LoadingService, public broker: BrokerService) {
  }

  close() {
    $('#modal').modal('hide');
    this.imgLateral = '';
    this.imgMobile = '';
    this.titulo = '';
    this.textoHTML = '';
    this.enlace = false;
    this.enlaceBoton = '';
    this.textBoton = '';
    this.tipo = '';
  }

  open( title:any, textHTML:any, imagen:any, imagenMobile:any, enlace:any, enlaceBoton:any, textBoton:any, type:any, externo:any = null, xmasFree = null, close = false ) {
    this.imgLateral = imagen;
    this.imgMobile = imagenMobile;
    this.titulo = title;
    this.textoHTML = textHTML;
    this.enlace = enlace;
    this.enlaceBoton = enlaceBoton;
    this.textBoton = textBoton;
    this.tipo = type;
    this.enlaceExterno = externo;
    this.closeB = close;

    // tslint:disable-next-line: only-arrow-functions
    setTimeout(function() {
      $('#modal').modal({backdrop: 'static', keyboard: false});
    }, 1000);
  }

  escaneoAnonimo( datos: NgForm ) {
    if ( datos.valid ) {
      this.loading.open();
    }
    this.broker.generic( this.email ).subscribe( (data: any) => {
      const model = this;
      setTimeout(function() {
        if ( data.stlTransactionData.errorCode === 'OK' ) {
          model.loading.close();
          model.anonimo = data;
          model.closeEscaneo();
          dataLayer.push({'event': 'optimize.activate'});
          $('#respuestaEscaneo').modal({backdrop: 'static', keyboard: false});
        } else {
          model.loading.close();
          model.open(
            'Estamos trabajando para prestarte un mejor servicio',
            // tslint:disable-next-line: max-line-length
            'Nuestra plataforma está siendo renovada para ti. Por favor intenta de nuevo en unos minutos.',
            'assets/img/images/Estamos_trabajando.svg',
            null,
            false,
            '',
            'Entendido',
            'ok'
          );
        }
      }, 4000);
      },
      (error) => {
        // this.router.navigate(['/error']);
        this.loading.close();
        this.open(
          'Estamos trabajando para prestarte un mejor servicio',
          // tslint:disable-next-line: max-line-length
          'Nuestra plataforma está siendo renovada para ti. Por favor intenta de nuevo en unos minutos.',
          'assets/img/images/Estamos_trabajando.svg',
          null,
          false,
          '',
          'Entendido',
          'ok'
        );
      }
    );
  }

  closeEscaneo() {
    $('#modalEscaneo').modal('hide');
  }
  closeRespuesta() {
    $('#respuestaEscaneo').modal('hide');
  }

  notificarOpen() {
    $('#covid').modal({backdrop: 'static', keyboard: false});
  }

  /*** Planes ***/
  /** Premium */
  modalPlans(funn:string){
    this.funnModal = funn;
    $('#planModal').modal('show');
  }

  /** Plan protege */
  modalProtege(funn: string){
    this.funnModal = funn;
    $('#planProtegeModal').modal('show');
  }

  /** Perfil de crédito */
  modalPerfil(funn: string){
    this.funnModal = funn;
    $('#planPerfilModal').modal('show');
  }

  /*** Reclamaciones ***/
  modalClaim(){
    $('#claimModal').modal('show');
  }

  /*** Aceptación de Publicidad ***/
  publicityClaim(){
    $('#publicityModal').modal('show');
  }

  /*** Error obetiendo campaña ***/
  errorCampaign(){
    $('#modalErrorCampaign').modal('show');
  }

  /*** Aceptación de Publicidad Time Out***/
  adverTimeOut(){
    $('#accepAdvrModal').modal('show');
  }  

  modalOkReclamacion(){
    $('#okReclamacionModal').modal('show');
  }


  modalVerifica(){
    $("#modalVerifica").modal("show")
  }

  modalIntermitencia(){
    $("#modalIntermitencia").modal("show")
  }

  modalAlertImpersonation(){
    $("#alert-impersonation").modal("show");
  }

  modalSendSMS(){
    $("#sendSMSModal").modal("show");
  }
  modalFreemium(){
    $("#freemiumModal").modal("show");
  }

    /*** Ponte al dia YA ***/
    modalPadYa(){
      $('#pdaYaModal').modal('show');
      setTimeout(() => {
        $(".content-list").children('p').css({'margin-bottom':'0px'});
        $(".content-list").children('a').css({'color':'#a5a5a8'});
        $(".content-list").children('p').children('a').css({'color':'#a5a5a8'});
        $(".content-list").children('ul').css({'margin-bottom':'0px','list-style':'none','margin-top':'8px'});
      }, 100);
    }
}
