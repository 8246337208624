import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLazyLoadImg]'
})
export class LazyLoadImgDirective {

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshould: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting){
          const imgElement = entry.target as HTMLImageElement;
          const src = imgElement.dataset['src'];
          if (src){
            imgElement.src = src;
            observer.unobserve(entry.target);
          }          
        }
      });
    }, options);
    observer.observe(this.element.nativeElement);
  }

}
