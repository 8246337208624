import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  current: any;
  stopAnimateRangeNum: boolean = false;

  constructor() { }

  close() {
    // tslint:disable-next-line: only-arrow-functions
    setTimeout(function () {
      $('#loading').modal('hide');
    }, 1000);
  }

  open() {    
    $('#loading').modal({ backdrop: 'static', keyboard: false });
  }

  animateRangeNumber(start:any, end:any, duration:any) {
    const range = end - start;
    this.current = start;
    const increment = end > start ? 2200 : -2200;
    const stepTime = Math.abs(Math.floor(duration / range));
    const obj = $('elementAnimateRangeNumber');
    const model = this;
    model.stopAnimateRangeNum = false;
    const timer = setInterval(function () {
      if (obj) {
        model.current += increment;
        if (model.current > end || model.stopAnimateRangeNum) {
          model.current = end;
          clearInterval(timer);
        }
      }
    }, stepTime);
  }

}
