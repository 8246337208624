import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Kinesis, config } from 'aws-sdk';
// import { Kinesis } from 'aws-sdk';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';
import { BrokerService } from './broker.service';

@Injectable({
  providedIn: 'root'
})
export class WebActivityService {

  private kinesis;
  private params: any = {};
  private llaves: any = {};

  constructor(public http2: HttpClient, private sesion: SesionService, public broker: BrokerService) {
    config.credentials = {
      "accessKeyId": this.getKeyId(),
      "secretAccessKey": this.getPC()
    }
    config.region = 'us-east-1';
    this.kinesis = new Kinesis({
      apiVersion: '2013-12-02'
    });
  }

  sendDataAWS(nameTag: any, idTag: any, timeOut: boolean) {
    const context = this;
    let today = new Date();
    today.setHours(today.getHours() - 5);
    context.params.partition = today.toISOString().substr(0, 7).replace('-', '');
    context.params.creationDate = today.toISOString() + "" + Math.floor(Math.random() * 10001);
    context.params.page = window.location.href;
    context.params.origin = this.getInfoDevice();
    context.params.env = environment.env;
    context.params.loginStatus = "NO_LOGIN";
    if (nameTag) {
      context.params.event = nameTag;
    }
    if (idTag) {
      context.params.idTag = idTag;
    }
    if (this.sesion) {
      if (this.sesion.user && this.sesion.user.documento) {
        context.params.loginStatus = "LOGGED";
        context.params.doc = this.sesion.user.documento;
        if (this.sesion.user.tipoDocumento) {
          context.params.typeDoc = this.sesion.user.tipoDocumento;
        }
      }
      if (this.sesion.dataUser) {
        if (this.sesion.dataUser.product) {
          if (this.sesion.dataUser.product == "MIDATACREDITO") {
            context.params.product = "MiDC";
          } else {
            context.params.product = this.sesion.dataUser.product;
          }
        }
        if (this.sesion.dataUser.params) {
          let plan = this.sesion.dataUser.params.find((param: any) => param.name === 'plan');
          let planStatus = this.sesion.dataUser.params.find((param: any) => param.name === 'planStatus');
          if (plan.value) {
            context.params.plan = plan.value;
          }
          if (planStatus.value) {
            context.params.planStatus = planStatus.value;
          }
        }
      }
    }

    var recordData: any = [];
    var record;
    if (config.credentials != null) {
      record = {
        Data: JSON.stringify(context.params),
        PartitionKey: 'partition-' + config.credentials.accessKeyId
      };
    }
    recordData.push(record);
    return new Promise((resolve, reject) => {
      this.kinesis.putRecords({
        Records: recordData,
        StreamName: 'MIDC-clicks-KinesisStreams'
      }, function (err: any) {
        if (err) {
          reject(err);
        }
        resolve({ success: true });
      });
      if (timeOut) {
        setTimeout(function () {
          resolve({ success: false });
        }, 3000);
      }
    });
  }

  getInfoDevice(): any {
    let userAgent = navigator.userAgent;
    userAgent = userAgent.replace('(KHTML, like Gecko)', '');
    if (userAgent.length > 150) {
      userAgent = userAgent.substr(0, 150);
    }
    return userAgent;
  }

  getPC() {
    return atob('YUtIVWdZc2NyUmJRNEFvN002WkJNcGFZTCtlYnpXMk1uclpHQnhzeQ==');
  }

  getKeyId() {
    return atob('QUtJQVFZWFIzQktORFpRVFhHS1E=');
  }

}
