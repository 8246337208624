import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { PlanesGuard } from './core/guards/planes.guard';
import { ValidateSesionGuard } from './core/guards/validate-sesion.guard';
import { ZonaPagoGuard } from './core/guards/zona-pago.guard';
// import { ClausulaPolizaComponent } from './pages/clausula-poliza/clausula-poliza.component';
import { ComparacionPlanesComponent } from './pages/comparacion-planes/comparacion-planes.component';
import { ErrorComponent } from './pages/error/error.component';
import { FaqComponent } from './pages/faq/faq.component';
// import { InicioComponent } from './pages/inicio/inicio.component';
import { PoliticasPrivacidadComponent } from './pages/politicas-privacidad/politicas-privacidad.component';
import { ServicioClienteComponent } from './pages/servicio-cliente/servicio-cliente.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { WebcheckoutConfirmComponent } from './pages/webcheckout-confirm/webcheckout-confirm.component';
import { WebcheckoutComponent } from './pages/webcheckout/webcheckout.component';
import { BlogComponent } from './pages/blog/blog.component'
import { PadAppComponent } from './pages/landings/pad-app/pad-app.component';
import { AutorizacionComponent } from './pages/autorizacion/autorizacion.component';
import { AutorizacionOpcionalComponent } from './pages/autorizacion-opcional/autorizacion-opcional.component';
import { FeriaComponent } from 'src/app/pages/landings/feria/feria.component';
import { PadComponent } from './pages/landings/pad/pad.component';
import { HomeComponent } from './pages/home/home.component';
// import { LayoutComponent } from './shared/layout/layout.component';
import { FraudeComponent } from './pages/landings/fraude/fraude.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [PlanesGuard, ValidateSesionGuard] },
  
  // Lazy Loading
  { path: 'freemium', canActivate:[ValidateSesionGuard], loadChildren: () => import('./modules/sessions/sessions.module').then(m => m.SessionsModule) },
  { path: 'private', canActivate:[ValidateSesionGuard], loadChildren: () => import('./modules/sessions/sessions.module').then(m => m.SessionsModule) },
  { path: 'landing', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule) },
  { path: 'pago', canActivate: [PlanesGuard, ZonaPagoGuard], loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule) },

  // Raíz
  { path: 'comparacion-planes', component: ComparacionPlanesComponent, canActivate: [PlanesGuard] },
  { path: 'politicas-privacidad', component: PoliticasPrivacidadComponent },
  { path: 'terminos-condiciones', component: TerminosCondicionesComponent },
  { path: 'prevencion-de-fraude', component: FraudeComponent},
  { path: 'feria', component: FeriaComponent },
  { path: 'deudas', component: PadComponent},
  // { path: 'poliza', component: ClausulaPolizaComponent },
  { path: 'faq', component: FaqComponent, canActivate: [PlanesGuard] },
  { path: 'contactanos', component: ServicioClienteComponent },
  { path: 'servicio-cliente', component: ServicioClienteComponent },
  { path: 'webcheckout/:documentId', component: WebcheckoutComponent },
  { path: 'webcheckout-confirm', component: WebcheckoutConfirmComponent },
  { path: 'blog/:article', component: BlogComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'alianzas', component: ComparacionPlanesComponent, canActivate: [PlanesGuard] },
  { path: 'autorizacion', component: AutorizacionComponent },
  { path: 'autorizacion-opcional', component: AutorizacionOpcionalComponent },
  { path: 'modulopadapp', component: PadAppComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
