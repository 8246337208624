<div *ngIf="publicidad == 'buscacredito'" class="boxPublicidad" [ngClass]="[publicidad,bordes,padding,sombra]">
  <div class="imgPublicidad">
    <img src="assets/img/freepremium/historico/IMG-P-Buscacredito.svg" alt="Mejora tu puntaje con buscacrédito"/>
  </div>
  <div class="contentPublicidad">
    <!-- <h3>{{ sesion.user != undefined ? sesion.user.nombre :'' }}, aplica sin costo a un crédito de hasta $750.000.</h3> -->
    <h3>¿Necesitas productos que mejoren tu puntaje?</h3>
    <p>Buscacrédito es un producto de Experian Colombia que te permite encontrar ofertas de crédito con nuestras entidades aliadas.</p>
    <a class="btnPublicidad" [ngClass]="[ubicacion+'-banner-buscacredito']" href="https://buscacredito.midatacredito.com/" 
      target="_blank" id="bannerBuscacredito" webActivity data-idtag="Freemium" data-nametag="ofertaBuscacredito"
      (click)="clic_inside_tool()">
      Buscar productos
      <i class="fa fa-chevron-right" [ngClass]="[ubicacion+'-banner-buscacredito']"></i>
    </a>
  </div>
</div>

<div *ngIf="publicidad == 'verifica'" class="boxPublicidad" [ngClass]="[publicidad,bordes,padding,sombra]">
  <div class="imgPublicidad">
    <img src="assets/img/freepremium/historico/Icono_verifica_Banner.svg" alt="Verifica midatacrédito"/>
  </div>
  <div class="contentPublicidad">
    <h3>¿Necesitas Verificar a otra persona en Datacrédito?</h3>
    <p>Verifica es un producto de Experian Colombia que te permite obtener un reporte de confiabilidad de otras personas por solo <b>$19.900.</b></p>
    <a class="btnPublicidad" [ngClass]="[ubicacion+'-banner-verifica']" href="https://www.midatacredito.com/verifica/#/" target="_blank" id="bannerVerifica" webActivity data-idtag="Freemium" data-nametag="ofertaBuscacredito">
      Verificar a otra persona
      <i class="fa fa-chevron-right" [ngClass]="[ubicacion+'-banner-verifica']"></i>
    </a>
  </div>
</div>

<div *ngIf="publicidad == 'oneshot'" class="boxPublicidad" [ngClass]="[publicidad,bordes,padding,sombra]">
  <div class="imgPublicidad">
    <img src="assets/img/freepremium/historico/Icono_oneshot_Banner.svg" alt="Obtén tu puntaje de datacrédito"/>
  </div>
  <div class="contentPublicidad">
    <!-- <h3>{{ sesion.user != undefined ? sesion.user.nombre :'' }}, aplica sin costo a un crédito de hasta $750.000.</h3> -->
    <h3>Obtén en tu correo tu Puntaje de Datacrédito.</h3>
    <p>El Plan Único Día de Experian Colombia te permite obtener un informe de tu perfil de crédito a la fecha pagando solo <b>$9.900.</b></p>
    <a class="btnPublicidad" [ngClass]="[ubicacion+'-banner-oneshot']" href="https://usuario.midatacredito.com/oneshot?product=midc" target="_blank" id="bannerOnsehot" webActivity data-idtag="Freemium" data-nametag="ofertaBuscacredito">
      Obtener mi puntaje
      <i class="fa fa-chevron-right" [ngClass]="[ubicacion+'-banner-onseshot']"></i>
    </a>
  </div>
</div>

<div *ngIf="publicidad == 'leyBorronCuentaNueva'" class="boxPublicidad" [ngClass]="[publicidad,bordes,padding,sombra]">
  <a href="https://soluciones.datacredito.com.co/ley-de-borron-y-cuenta-nueva-datacredito-2021?utm_source=Referral&utm_medium=midatacredito.com&utm_campaign=main_banner" target="_blank">
    <img src="assets/img/home/banner-MIDC-HD_2.png" alt="Ley borrón y cuenta nueva" title="Ley borrón y cuenta nueva" class="byCN" appLazyLoadImg/>
  </a>  
</div>

<div id="bannerNotificaciones" *ngIf="publicidad == 'bannerNotificaciones'" class="boxPublicidad" [ngClass]="[publicidad,bordes,padding,sombra]">
  <a href="{{routeS3}}midc_legal/sistema_alertas_obligaciones_nuevas.pdf" target="_blank">
    <img src="assets/img/home/banner-MIDC--alertas_2.png" alt="Mitiga el riesgo de suplantación de identidad" title="Sistema de alertas" class="byCN" appLazyLoadImg/>
  </a>  
</div>

<div *ngIf="publicidad == 'datacredito-empresas'" class="boxBanner" [ngClass]="[publicidad,bordes,padding,sombra,marginTop,flexTable]">  
  <div class="contentPublicidad datacredito-empresas">
    <h3>
      <span class="a">Si eres una empresa</span>
      <span class="b">, recuerda que también puedes</span> 
      <span class="c"> consultar gratis tu historia de crédito</span> <a href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" target="_blank">AQUÍ</a></h3>
  </div>
  <div class="imgPublicidad">
    <img src="assets/img/freepremium/publicidad/Icono_banner_SME.svg" alt="Datacrédito Experian - Empresas"  />
  </div>
  <div class="brandPublicidad">
    <img src="assets/img/freepremium/publicidad/Datacredito_Experian_Empresas.svg" alt="Logo Datacrédito Experian - Empresas" class="logo-datacredito-m" />
  </div>
</div>