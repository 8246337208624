<app-banners *ngIf="sliders.length > 0" class="banners" [bann]="sliders"></app-banners>
<section id="planesPreId" class="bg-plan">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div *ngIf="contenido_movil; else escritorio" id='div-gpt-ad-1722271937484-0'
                    class="commercial-banner-mobile" [ngClass]="checkBannerInicioMobile()"></div>
                <ng-template #escritorio>
                    <div id='div-gpt-ad-1722271695890-0' class="commercial-banner" [ngClass]="checkBannerInicio()"></div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="box-periodicity">
        <h2>Elige la duración de tu plan:</h2>
        <div class="custom-slider">
            <input type="range" min="1" max="100" value="1" id="r-periodicity" (change)="validRange($event)"/>
            <div class="text-slider">
                <div class="box-text left">
                    <p [ngClass]="{ 'active' : (typePlan == '1')}">Mensual</p>
                </div>
                <div class="box-text center">
                    <p [ngClass]="{ 'active' : (typePlan == '2')}">Trimestral<br/><b>-{{sesion.planes.planes.pre.trimestral[3]}} Dto.</b></p>
                </div>
                <div class="box-text right">
                    <p [ngClass]="{ 'active' : (typePlan == '4')}">Anual<br/><b>-{{sesion.planes.planes.pre.anual[3]}} Dto.</b></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="box-plan-n d-none d-md-block" *ngIf="!contenido_movil">
                    <h3>PLAN PERFIL DE CRÉDITO</h3>
                    <div class="val-plan-n">
                        <p>${{selPlan('per').valorMes}}</p><p class="ligth">/Mes</p>
                    </div>
                    <button class="{{'cta-new-plan-per-'+typePlan}}" (click)="seleccionarPlan(selPlan('per'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    <h4>Adquiriendo el <span>PLAN PERFIL DE CRÉDITO</span> puedes:</h4>
                    <div class="bullets-n">
                        <div class="bullet" *ngFor="let item of twoPartsPerfil[0]">
                            <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}"/>
                            <p>{{item.benefit}}</p>
                        </div>
                        <div class="collapse" id="collapsePerfil">
                            <div class="bullet" *ngFor="let item of twoPartsPerfil[1]">
                                <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btnCollapse d-none d-md-block" data-toggle="collapse" href="#collapsePerfil" role="button" aria-expanded="false" aria-controls="collapsePerfil">Ver todos los beneficios del plan</button>
                <div class="d-block d-md-none" *ngIf="contenido_movil">
                    <div class="box-plan-n mobile">
                        <h3>PLAN PERFIL DE CRÉDITO</h3>
                        <div class="box-head">
                            <div class="info">
                                <div class="val-plan-n">
                                    <p>${{selPlan('per').valorMes}}</p><p class="ligth">/Mes</p>
                                </div>
                                <p class="text-fact">Facturación mensual</p> 
                            </div>
                            <button class="{{'cta-mobile-new-plan-per-'+typePlan}}" (click)="seleccionarPlan(selPlan('per'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        </div>
                        <div id="per-bullets" class="bullets-n">
                            <hr>
                            <h4>Adquiriendo el <span>PLAN PERFIL DE CRÉDITO</span> puedes:</h4>
                            <div class="bullet" *ngFor="let item of allPlansBenefitsPerfil">
                                <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                        <div class="more-bullets">
                            <button (click)="showBullets('per',$event)">Ver todos los beneficios del plan<i class="fa fa-chevron-down"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="box-plan-n d-none d-md-block" *ngIf="!contenido_movil">
                    <h3>PLAN PROTEGE</h3>
                    <div class="val-plan-n">
                        <p>${{selPlan('ant').valorMes}}</p><p class="ligth">/Mes</p>
                    </div>
                    <button class="{{'cta-new-plan-ant-'+typePlan}}" class="{{'cta-new-plan-ant-'+typePlan}}" (click)="seleccionarPlan(selPlan('ant'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    <h4>Adquiriendo el <span>PLAN PROTEGE</span> puedes:</h4>
                    <div class="bullets-n">
                        <div class="bullet" *ngFor="let item of twoPartsProtege[0]">
                            <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}"/>
                            <p>{{item.benefit}}</p>
                        </div>
                        <div class="collapse" id="collapseProtege">
                            <div class="bullet" *ngFor="let item of twoPartsProtege[1]">
                                <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btnCollapse d-none d-md-block" data-toggle="collapse" href="#collapseProtege" role="button" aria-expanded="false" aria-controls="collapseProtege">Ver todos los beneficios del plan</button>
                <div class="d-block d-md-none" *ngIf="contenido_movil">
                    <div class="box-plan-n mobile">
                        <h3>PLAN PROTEGE</h3> 
                        <div class="box-head">
                            <div class="info">
                                <div class="val-plan-n">
                                    <p>${{selPlan('ant').valorMes}}</p><p class="ligth">/Mes</p>
                                </div>
                                <p class="text-fact">Facturación mensual</p> 
                            </div>
                            <button class="{{'cta-mobile-new-plan-ant-'+typePlan}}" (click)="seleccionarPlan(selPlan('ant'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        </div>                    
                        <div id="ant-bullets" class="bullets-n">
                            <hr>
                            <h4>Adquiriendo el <span>PLAN PROTEGE</span> puedes:</h4>
                            <div class="bullet" *ngFor="let item of allPlansBenefitsProtege">
                                <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                        <div class="more-bullets">
                            <button (click)="showBullets('ant',$event)">Ver todos los beneficios del plan<i class="fa fa-chevron-down"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="box-plan-n premium d-none d-md-block" *ngIf="!contenido_movil">
                    <img class="cinta" src="assets/img/icons/cinta_premiun.svg" alt="Estelar" />
                    <h3>PLAN PREMIUM</h3>
                    <div class="val-plan-n">
                        <p>${{selPlan('pro').valorMes}}</p><p class="ligth">/Mes</p>
                    </div>
                    <button class="{{'cta-new-plan-pro-'+typePlan}}" (click)="seleccionarPlan(selPlan('pro'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    <h4>Adquiriendo el <span>PLAN PREMIUM</span> puedes:</h4>
                    <div class="bullets-n">
                        <div class="bullet" *ngFor="let item of twoPartsPremium[0], let i=index">
                            <img src="{{item.iconM}}" alt="{{item.alt}}" title="{{item.alt}}"/>
                            <p>{{item.benefit}}</p>
                        </div>
                        <div class="collapse" id="collapsePremium">
                            <div class="bullet" *ngFor="let item of twoPartsPremium[1]">
                                <img src="{{item.iconM}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btnCollapse d-none d-md-block premium" data-toggle="collapse" href="#collapsePremium" role="button" aria-expanded="false" aria-controls="collapsePremium">Ver todos los beneficios del plan</button>
                <div class="d-block d-md-none" *ngIf="contenido_movil">
                    <div class="box-plan-n premium mobile">
                        <h3>PLAN PREMIUM</h3>
                        <div class="box-head premium">
                            <div class="info">
                                <div class="val-plan-n">
                                    <p>${{selPlan('pro').valorMes}}</p><p class="ligth">/Mes</p>
                                </div>
                                <p class="text-fact">Facturación mensual</p> 
                            </div>
                            <div>
                                <p class="txtPre d-none">Mejor oferta</p>
                            <button class="{{'cta-mobile-new-plan-pro-'+typePlan}}" (click)="seleccionarPlan(selPlan('pro'))">Adquirir plan<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                            </div>
                        </div>                        
                        <div id="pro-bullets" class="bullets-n">
                            <hr>
                            <h4>Adquiriendo el <span>PLAN PREMIUM</span> puedes:</h4>
                            <div class="bullet" *ngFor="let item of allPlansBenefitsPremium, let i=index">
                                <img src="{{item.icon}}" alt="{{item.alt}}" title="{{item.alt}}" appLazyLoadImg/>
                                <p>{{item.benefit}}</p>
                            </div>
                        </div>
                        <div class="more-bullets">
                            <button (click)="showBullets('pro',$event)">Ver todos los beneficios del plan<i class="fa fa-chevron-down"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container h-100 mdc-h-auto pl-pr-0">
        <div class="mobilePlans">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">                   
                        <div class="row">
                            <div class="col-12 compPlanes">
                                <a href="/comparacion-planes" class="btn-sec h-lg-btn w-100-btn">
                                    Compara todos nuestros planes 
                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12 pl-pr-30">
                                <div class="legal">
                                    * Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.
                                </div>
                            </div>
                        </div> 
                        <div class="container testimonials" *ngIf="testimonials.length > 0">
                            <h2 class="title">¿Qué dicen nuestros usuarios?</h2>
                            <hr>
                            <div class="box-all">
                                <div class="box-people" *ngFor="let test of testimonials">
                                    <div class="icon-people">
                                        <img src="assets/img/home/testimonial.svg" alt="Comentario de usuario" title="Testimonios" appLazyLoadImg/>					
                                    </div>
                                    <div class="qualify d-none d-md-block">
                                        <i class="fa fa-star" aria-hidden="true" id="1star" [ngClass]="validarEstrella(1, test.qualify)"></i>
                                        <i class="fa fa-star" aria-hidden="true" id="2star" [ngClass]="validarEstrella(2, test.qualify)"></i>
                                        <i class="fa fa-star" aria-hidden="true" id="3star" [ngClass]="validarEstrella(3, test.qualify)"></i>
                                        <i class="fa fa-star" aria-hidden="true" id="4star" [ngClass]="validarEstrella(4, test.qualify)"></i>
                                        <i class="fa fa-star" aria-hidden="true" id="5star" [ngClass]="validarEstrella(5, test.qualify)"></i>
                                    </div>
                                    <p>{{test.description}}</p>
                                    <div class="d-flex">
                                        <p class="author">{{test.author}}</p>
                                        <div class="qualify d-flex d-md-none">
                                            <i class="fa fa-star" aria-hidden="true" id="1star" [ngClass]="validarEstrella(1, test.qualify)"></i>
                                            <i class="fa fa-star" aria-hidden="true" id="2star" [ngClass]="validarEstrella(2, test.qualify)"></i>
                                            <i class="fa fa-star" aria-hidden="true" id="3star" [ngClass]="validarEstrella(3, test.qualify)"></i>
                                            <i class="fa fa-star" aria-hidden="true" id="4star" [ngClass]="validarEstrella(4, test.qualify)"></i>
                                            <i class="fa fa-star" aria-hidden="true" id="5star" [ngClass]="validarEstrella(5, test.qualify)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="row justify-content-center">
                            <div id="carouselExampleControls" class="carousel" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <app-publicidad-productos [publicidad]="'bannerNotificaciones'" [bordes]="'bordesTodo'" [padding]="'paddingSmall'" [sombra]="'sombraCaja'" [ubicacion]="'home'">
                                    </app-publicidad-productos>
                                  </div>
                                </div>
                              </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>       
    </div>
</section>
<div class="container-fluid px-0 box-escaneo">
    <app-escaneo></app-escaneo>
    <section class="bg-bc mt-md-5 mt-0" *ngIf="Bc">
        <div class="container mdc-text-primary">
            <div class="row text-center mt-md-5 mt-0 pt-3">
                <div class="col-12 col-md-6">
                    <img src="assets/img/images/Buscacredito_banner.svg" class="mdc-img-header d-none d-md-block" alt="Buscacrédito" title="Buscacrédito">
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0">
                    <img src="assets/img/logos/logo_buscacredito.svg" width="90%" class="mdc-img-header d-block" alt="Logo buscacrédito" title="Logo Buscacrédito">
                    <hr class="mdc-hr my-5">
                    <h3 class="mdc-text-l mdc-text-3 mcd-fw-bold text-center text-md-left pb-5">
                        Ahora en <b>Midatacrédito</b> te ayudamos a <b>encontrar el crédito</b> que mejor se adapte a ti
                    </h3>
                    <a [href]="linkDc" target="_blank" rel="noopenner nofollow">
                        <button type="submit" name="" id="" class="btn mdc-btn-primary btn-lg btn-block pr-md-4 m-auto m-md-0">
                            Ir a Buscacrédito<i class="fa fa-chevron-right float-right mt-arrow"></i>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </section>
</div>
<section class="experian">
    <div class="modal fade hide" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-7 order-1 order-md-0">
                                <p class="title-modal-experian mb-4">En Midatacredito tu bienestar personal y financiero son nuestra prioridad</p>
                                <p class="subtitle-modal-experian mb-4">Evita desplazamientos innecesarios. Consulta totalmente <span class="bold">GRATIS</span> el estado de tu historia de crédito desde la comodidad de tu casa</p>
                                <p class="bold">Pensando en ti, hemos habilitado la <span>consulta gratuita</span> del resumen de tu historia de crédito completa hasta el día <span>miércoles 15 de abril</span></p>
                                <div class="btn-next my-4">
                                    <a data-idtag="INICIO3" data-nametag="consultar-consultarGratis" (click)="redirectExterno(linkLogin,'consultar-consultarGratis','INICIO3')" rel="noopenner nofollow" class="btn btn-primary" style="cursor: pointer;">Consultar GRATIS</a>
                                    <span class="arrow-right"><img src="../../../assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple" appLazyLoadImg></span>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-0 order-md-1 mb-3 middle">
                                <img src="../../../assets/img/images/consult.svg" class="img-cosult" alt="Conoce tu reporte crediticio" title="Consultar reporte crediticio" appLazyLoadImg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</section>

