import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SesionService } from '../../core/services/sesion.service';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

	articleUrl: any;
	embebed:SafeResourceUrl="";

	constructor(
		private activateRoute: ActivatedRoute,
		public sesion: SesionService,
		private domSanitizer: DomSanitizer
	) { 
		this.goArticle();
	}

	ngOnInit() {
	}

	goArticle() {
		var urlArticle = this.activateRoute.snapshot.paramMap.get('article');
		if(urlArticle != null){
			this.embebed = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://blog.midatacredito.com/${urlArticle}`);
		}else{
			this.embebed = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://blog.midatacredito.com/`);
		}
		

	}

}
