<div class="escaneoNew">
    <div class="principalEsc">
        <div class="textosEsc">
            <div class="tituloEsc">Conoce aquí si tu información se encuentra comprometida en la red.</div>
            <div class="descriptionEsc">Registra tu correo electrónico y escanea la red <span>GRATIS</span> en busca de información comprometida.</div>
        </div>
        <div class="imagenEsc">
            <img src="assets/img/images/escaneo_new.svg" appLazyLoadImg
                alt="Conoce aquí si tu información se encuentra comprometida en la red"
                title="Conoce aquí si tu información se encuentra comprometida en la red" />
        </div>
        <div class="clearFloat"></div>
    </div>
    <div class="backForm">
        <form (ngSubmit)="escaneoAnonimo(datos)" #datos="ngForm" autocomplete="off">
            <div class="formEsc campoEsc">
                <div class="formEsc iconEsc">
                    <i class=" fa fa-envelope-o"></i>
                </div>
                <div class="formEsc inputEsc">
                    <input type="text" class="form-control font-weight-lighter" name="mail" id="nameHolderP"
                        placeholder="Ingresa tu correo electrónico" required="required"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$" [(ngModel)]="email"
                        #emailAnonimo="ngModel"
                        [ngClass]="{'is-valid': emailAnonimo.valid, 'is-invalid': ( ( emailAnonimo.errors?.['required'] || emailAnonimo.errors?.['pattern'] ) && emailAnonimo.touched ) }">
                    <!-- <div *ngIf="emailAnonimo?.errors?.['required']" class="invalid-feedback">Ingresa un correo electrónico.</div>
                    <div *ngIf="emailAnonimo?.errors?.['pattern']" class="invalid-feedback">Correo electrónico incorrecto.</div> -->
                </div>
            </div>
            <div class="formEsc botonEsc">
                <button [disabled]="!(emailAnonimo.valid && termsDark)" webActivity data-idtag="ESCANEO1" data-nametag="escanear-verificarEnLaRed" id="botonEscaneo"
                    type="submit">
                    Verificar en la red <i class="fa fa-chevron-right float-right"></i>
                </button>
            </div>
        </form>
    </div>
    <div class="legalEsc">
        <input type="checkbox" [(ngModel)]="termsDark" name="termsContact" #termsConcat="ngModel" />
        <p>
            Autorizo el tratamiento de mis datos personales para que sean utilizados por Experian Midatacrédito de
            acuerdo con esta autorización de <a (click)="showTerms()"><u>tratamiento de datos personales.</u> <i class="fa fa-angle-down"></i></a>
        </p>
        <p class="text-ad">
            Al seleccionar este checkbox autorizo a EXPERIAN COLOMBIA S.A., identificada con NIT. 900.422.614-8 para
            que, en su calidad de Responsable del Tratamiento, recopile y en general realice el tratamiento de mi correo
            electrónico con el fin de (i) Almacenarlo en sus bases de datos; (ii) Adelantar las gestiones pertinentes
            tendientes a identificar y escanear mis datos en la dark web y redes sociales, con el propósito de alertarme
            sobre un posible uso indebido de ellos en la web; (iii) Enviarme comunicaciones asociadas al servicio de
            análisis en la dark web; (iv) Atender requerimientos de autoridades en ejercicio de sus funciones; (v)
            Conservarla de forma indefinida para fines relacionados con análisis estadísticos, desarrollo y mejora de
            productos contratados, históricos y/o para cumplir con obligaciones legales respecto a conservación de
            información; y (vi) Compartirla con terceros Encargados del Tratamiento para dar cumplimiento a las
            finalidades previamente mencionadas. <br/>Declaro conocer que como titular de la información tengo derecho a
            conocer, actualizar o rectificar mis datos personales, presentar quejas ante la Superintendencia de
            Industria y Comercio, suprimir o eliminar mis datos de las bases de datos de EXPERIAN COLOMBIA, lo cual solo
            procederá en caso de que no se tenga una obligación legal o contractual para mantenerla, así como solicitar
            prueba de la autorización otorgada o revocarla, contactándome por el canal de contacto de
            www.midatacredito.com, enviando comunicación a la Carrera 7 # 76 – 35 o en el Centro de Experiencia ubicado
            en la Avenida Américas # 62-84 Piso 2 Local 71-72, así como en el correo electrónico
            servicioalciudadano@experian.com, y que podré obtener mayor información sobre el tratamiento de mi
            información personal accediendo a la Política de Tratamiento de Datos Personales de EXPERIAN COLOMBIA en el
            siguiente 
            <a href="{{url_politica_privacidad}}" target="_blank">link</a>
        </p>
    </div>
</div>

<div class="escaneo w-100">
    <div class="r-escaneo py-3 py-md-5" *ngIf="sesion.anonimo">
        <button type="button" class="close pr-3" (click)="cerrarAnonimo()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="container">
            <div class="row m-0">
                <div class="col-12 col-md-3 d-flex">
                    <img src="assets/img/images/Scan_NoAlerts.svg"
                        *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount === 0"
                        class="justify-content-center align-self-center w-100 m-auto" alt="No alerts">
                    <img src="assets/img/images/Scan_Alert.svg" alt="recibe alerta financieras"
                        *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount !== 0"
                        class="justify-content-center align-self-center w-100 m-auto">
                </div>
                <div class="col-12 col-md-4 d-flex mt-3 mb-4 mb-md-0 mt-md-0 border-escaneo">
                    <div class="justify-content-center align-self-center"
                        *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount === 0">
                        <p class="mdc-text-white mdc-text-2 mdc-text-l mdc-line-height mb-4 text-center text-md-left">
                            ¡Puedes Estar Tranquilo!</p>
                        <p class="mdc-text-white mdc-text-3-5 text-center text-md-left">Escaneamos la red en busca de
                            información comprometida y no hemos encontrado nada de que preocuparse.</p>
                    </div>
                    <div *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount !== 0"
                        class="justify-content-center align-self-center">
                        <p class="mdc-text-white mdc-text-2 mdc-text-l mdc-line-height mb-4 text-center text-md-left">
                            ¡Tu contraseña está en riesgo!</p>
                        <p class="mdc-text-white mdc-text-sb2 text-center text-md-left"
                            *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount === 1">Encontramos tu
                            email y contraseña comprometidos en <span class="mdc-text-sb3 mdc-text-3-5-3">{{
                                sesion.anonimo?.stlTransactionData.responseMetadata.recordCount }}
                                página</span> de la dark web</p>
                        <p class="mdc-text-white mdc-text-sb2 text-center text-md-left"
                            *ngIf="sesion.anonimo?.stlTransactionData.responseMetadata.recordCount > 1">Encontramos tu
                            contraseña expuesta en <br class="d-none d-md-block"> <span
                                class="mdc-text-sb3 mdc-text-3-5-3">{{
                                sesion.anonimo?.stlTransactionData.responseMetadata.recordCount }}
                                páginas</span> diferentes de la dark web</p>
                    </div>
                </div>
                <div class="col-12 col-md-5 px-5 detalle">
                    <p class="mdc-text-white mdc-text-3-5 mdc-text-sb1 mb-3">
                        Mantén protegida y monitoreada tu información personal con Midatacrédito y evita:
                    </p>
                    <ul class="mdc-text-white mb-4 mdc-text-3-5">
                        <li class="mt-3">Compras online en tu nombre.</li>
                        <li class="mt-3">Que tu información privada sea revelada.</li>
                        <li class="mt-3">El uso de tu cuenta para actividades delictivas que generen perjuicios a tu
                            nombre.</li>
                        <li class="mt-3">Los envíos masivos de spam o virus a tus contactos.</li>
                    </ul>
                </div>
                <button type="button" class="btn btn-block mdc-btn-alter m-auto"
                    (click)="seleccionarPlan(sesion.planes.planes?.ant, '1', sesion.planes.planes?.ant?.mensual?.[1], sesion.planes.planes?.ant?.mensual?.[0])">
                    Suscríbete ya <i class="fa fa-chevron-right float-right  mt-arrow"></i>
                </button>
            </div>
        </div>
    </div>
</div>