<header class="header fixed-top">
    <div class="container-fluid">
        <div class="row" *ngIf="!sesion.loggedIn">
            <nav class="navbar navbar-midc" [ngClass]="{'scrolled': scroll}" *ngIf="devoluciones">
                <a class="navbar-brand" [routerLink]="linklogo" ng-if="sessionAct.dirLogo==undefined">
                    <img class="sodimac" src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">
                </a>
                <button class="navbar-toggler collapsed new-collapsed-btn" id="btnMenuMobile" type="button" (click)="openMenu()">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
            </nav>
            <nav class="navbar navbar-midc"  [ngClass]="{'scrolled': scroll,  'contactanos': url == '/contactanos'}" *ngIf="navComplete">
                <a class="navbar-brand" [routerLink]="linklogo" ng-if="sessionAct.dirLogo==undefined">
                    <img [src]="setLogo()" class="d-block" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">                
                </a>
                <a href="{{linkLogin+'&utm_source=MIDC&utm_medium=HEADER&utm_campaign=HOME'}}" class="btnIngresar orange d-inline-block d-md-none">Ingresar</a>
                <!----------nuevo menu---------->
                <div class="mn-right d-none d-md-block">
                    <ul class="menu-lista">
                        <li class="nav-lista">
                            <div class="btn-group dropMenu ">
                                <a class="nav-link only-link" (click)="clic_header_menu('Negociar mis Obligaciones')" routerLink="/deudas" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERPAD','utm_campaign':'HOME'}" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                    Negociar mis Obligaciones
                                    </a>
                            </div>
                        </li>
                        <li class="nav-lista">
                            <div class="btn-group dropMenu ">
                                <a class="nav-link only-link" (click)="clic_header_menu('Buscar un Crédito')" [href]="linkBc+'?utm_source=MIDC&utm_medium=HEADERBC&utm_campaign=HOME'" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                    Buscar un Crédito
                                    </a>
                            </div>
                        </li>
                        <li class="nav-lista">
                            <div class="btn-group dropMenu ">
                                <a class="nav-link only-link" (click)="clic_header_menu('Comparar Planes')"  routerLink="/comparacion-planes" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERMIDC','utm_campaign':'HOME'}" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                    Comparar Planes
                                    </a>
                            </div>
                        </li>
                        <!-- <li class="nav-lista">
                            <div class="btn-group dropMenu ">
                                <button type="button" (click)="clic_header_menu('Otras Soluciones')" class="nav-link dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                    Otras soluciones
                                </button>
                                <div class="dropdown-menu iDropMenu">
                                    <div class="contUp">
                                        <i class="arrowUp"></i>
                                      </div>
                                    <ul>
                                    <div>
                                        <li><a href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span (click)="clic_header_menu('Datacrédito Empresas | Otras Soluciones')">Datacrédito Empresas</span></a></li>
                                        <li (click)="redirectExterno(linkLogin,'login-iniciaSesion','HEADER1', true)"><i class="fa fa-chevron-right"></i><span>Consultar mi Historia de crédito</span></li>
                                        <li routerLink="/landing/perfil-credito"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Mejora mi puntaje de Datacrédito | Otras soluciones')">Mejora mi Puntaje de Datacrédito</span></li>
                                        <li routerLink="/landing/protege"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Protege mi presencia en internet | Otras soluciones')">Protege mi presencia en internet</span></li>
                                        <li routerLink="/landing/premium"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Evitar el Fraude en mis compras | Otras soluciones')">Evitar el Fraude en mis compras</span></li>
                                        <li routerLink="/comparacion-planes" class="mb-1"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Compara nuestros planes | Otras soluciones')">Compara nuestros planes</span></li>
                                    </div>
                                    </ul>
                                </div>
                            </div>
                        </li> -->
                        <li class="nav-lista">
                            <div class="btn-group dropMenu">
                                <button type="button" (click)="clic_header_menu('Ayudas')" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                Ayudas
                                </button>
                                <div class="dropdown-menu iDropMenu">
                                <div class="contUp">
                                    <i class="arrowUp"></i>
                                </div>
                                <ul>
                                    <div>
                                        <li><a href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span (click)="clic_header_menu('Datacrédito Empresas | Ayudas')">Datacrédito Empresas</span></a></li>
                                        <li (click)="redirectExterno(linkLogin,'login-iniciaSesion','HEADER1', true)"><i class="fa fa-chevron-right"></i><span>Consultar mi Historia de crédito</span></li>
                                        <li routerLink="/landing/perfil-credito"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Mejora mi puntaje de Datacrédito | Ayudas')">Mejora mi Puntaje de Datacrédito</span></li>
                                        <li routerLink="/landing/protege"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Protege mi presencia en internet | Ayudas')">Protege mi presencia en internet</span></li>
                                        <li routerLink="/landing/premium"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Evitar el Fraude en mis compras | Ayudas')">Evitar el Fraude en mis compras</span></li>
                                    <li routerLink="/faq"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Preguntas frecuentes | Ayudas')">Preguntas frecuentes</span></li>
                                    <li><a href="https://www.datacredito.com.co/habeas-data.jsp" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span (click)="clic_header_menu('Ley habeas Data | Ayudas')">Ley habeas Data</span></a></li>
                                    <li  class="mb-1"><i class="fa fa-chevron-right"></i><span (click)="irServicioCliente()">Contáctanos</span></li>
                                    </div>
                                </ul>
                                </div>
                            </div>
                        </li>                        
                        <li class="nav-lista">
                            <div class="btn-group dropMenu ">
                                <a class="nav-link blog" (click)="clic_header_menu('Blog',true)" routerLink="/blog"  [queryParams]="{utm_source: 'MIDC', utm_medium: 'HEADERBLOG', utm_campaign: 'HOME'}" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                                    Blog
                                    </a>
                            </div>
                        </li>
                    </ul>
                    <button data-idtag="HEADER5" data-nametag="registrar-registrate"
                    (click)="redirectExterno(linkLogin+'&utm_source=MIDC&utm_medium=HEADER&utm_campaign=HOME','login-iniciaSesion','HEADER1', true, true)"
                    rel="noopenner nofollow" class="btn-ingresar orange" [ngClass]="{'border-secondary': !textoBlanco && !scroll,'mdc-text-secondary': !textoBlanco && !scroll }">Ingresar <i class="fa fa-chevron-right float-right  mt-arrow ml-3"></i></button>
                </div>
                <button *ngIf="!protege" class="navbar-toggler collapsed new-collapsed-btn" id="btnMenuMobile" type="button" (click)="openMenu()">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
            </nav>
        </div>
    </div>
    <nav *ngIf="sesion.loggedIn" class="navbar navbar-midc" [ngClass]="{'scrolled': scroll, 'intern': url != '/',  'contactanos': url == '/contactanos'}">
        <div class="navbar-logo">
            <a *ngIf="sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS'" class="navbar-brand" [routerLink]="linklogo">
                <img [src]="setLogo()" class="d-none d-md-block" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">
                <img  src="assets/img/logos/logo_midc_white.svg" class="d-inline d-md-none" alt="Midatacrédito mobile">
            </a>
            <a *ngIf="sesion.viewFree" class="navbar-brand" [routerLink]="linklogo">
                <img [src]="setLogo()" class="d-none d-md-block" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">  
                <img  src="assets/img/logos/logo_midc_white.svg" class="d-inline d-md-none" alt="Midatacrédito mobile">
            </a>
            <button class="navbar-toggler collapsed new-collapsed-btn" id="btnMenuMobile" type="button"  (click)="openMenu()">
                <i class="fa fa-bars" aria-hidden="true"></i>
                <p *ngIf="sesion.flagNewMenu" class="dot">&#183;</p>
            </button>
        </div>
        <div *ngIf="!protege" class="collapse navbar-collapse" id="navbarNav_0">
            <ul class="menu-lista ">
                <li class="nav-lista">
                    <div class="btn-group dropMenu " >
                        <a class="nav-link only-link" [ngClass]="{'mdc-text-secondary': !scroll && !textoBlanco, 'text-white': scroll || (!scroll && textoBlanco)}" 
                        routerLink="/deudas" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERPAD','utm_campaign':'HOME'}" (click)="clic_header_menu('Negociar mis Obligaciones')">
                        Negociar mis Obligaciones
                        </a> 
                    </div>
                </li>
                <li class="nav-lista">
                    <div class="btn-group dropMenu ">
                        <a class="nav-link only-link" (click)="clic_header_menu('Buscar un Crédito')"  [href]="linkBc+'?utm_source=MIDC&utm_medium=HEADERBC&utm_campaign=HOME'" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                            Buscar un Crédito
                            </a>
                    </div>
                </li>
                <li class="nav-lista">
                    <div class="btn-group dropMenu ">
                        <a class="nav-link only-link" (click)="clic_header_menu('Comparar Planes')" routerLink="/comparacion-planes" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERMIDC','utm_campaign':'HOME'}" [ngClass]="{'mdc-text-secondary': !textoBlanco && !scroll }">
                            Comparar Planes
                            </a>
                    </div>
                </li>
                <!-- <li class="nav-lista">
                    <div class="btn-group dropMenu ">
                        <button type="button" class="nav-link  dropdown-toggle" [ngClass]="{'mdc-text-secondary': !scroll && !textoBlanco}"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="" (click)="clic_header_menu('Otras Soluciones')">
                            Otras soluciones
                        </button>
                        <div class="dropdown-menu iDropMenu">
                            <div class="contUp">
                                <i class="arrowUp"></i>
                              </div>
                            <ul>
                            <div>
                                <li class="mb-1" (click)="clic_header_menu('Datacrédito Empresas | Otras soluciones')"><a href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span>Datacrédito Empresas</span></a></li>
                            </div>
                            </ul>
                        </div>
                    </div>
                </li> -->
                <li class="nav-lista">
                    <div class="btn-group dropMenu ">
                        <button type="button" class="nav-link  dropdown-toggle" [ngClass]="{'mdc-text-secondary': !scroll && !textoBlanco}" data-toggle="dropdown"  
                            aria-haspopup="true" aria-expanded="false" id="" (click)="clic_header_menu('Ayudas')">
                        Ayudas
                        </button>
                        <div class="dropdown-menu iDropMenu ayudas">
                        <div class="contUp">
                            <i class="arrowUp"></i>
                        </div>
                        <ul>
                            <div>
                            <li (click)="clic_header_menu('Datacrédito Empresas | Ayudas')"><a href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span>Datacrédito Empresas</span></a></li>
                            <li routerLink="/faq"><i class="fa fa-chevron-right"></i><span (click)="irOtrasSoluciones('Preguntas frecuentes | Ayudas')">Preguntas frecuentes</span></li>
                            <li (click)="clic_header_menu('Ley Habeas Data | Ayudas')"><a href="https://www.datacredito.com.co/habeas-data.jsp" rel="noopener" target="_blank"><i class="fa fa-chevron-right"></i><span>Ley habeas Data</span></a></li>
                            <li  class="mb-1"><i class="fa fa-chevron-right"></i><span (click)="irServicioCliente()">Contáctanos</span></li>
                            </div>
                        </ul>
                        </div>
                    </div>
                </li>                
                <li class="nav-lista">
                    <div class="btn-group dropMenu " >
                        <a class="nav-link  blog" [ngClass]="{'blog-scroll': !scroll && !textoBlanco,'mdc-text-secondary': !scroll && !textoBlanco, 'text-white': scroll || (!scroll && textoBlanco)}" 
                        routerLink="/blog"  [queryParams]="{utm_source: 'MIDC', utm_medium: 'HEADERBLOG', utm_campaign: 'HOME'}" (click)="clic_header_menu('Blog',true)">
                            Blog
                        </a> 
                    </div>
                </li>                
            </ul>
            <a class="btn-ingresar" [ngClass]="{'border-secondary': !scroll && !textoBlanco, 'border-white': scroll || (!scroll && textoBlanco)}"  role="button" aria-expanded="false" (click)="openMenu()">
                <span class="d-md-inline d-none" [ngClass]="{'mdc-text-secondary': !scroll && !textoBlanco, 'text-white': scroll || (!scroll && textoBlanco)}">
                    Hola {{ sesion.user.nombre | titlecase }}
                </span>
                <i *ngIf="!scroll && !textoBlanco" class="fa fa-chevron-down mdc-text-secondary" aria-hidden="true"></i>
                <i *ngIf="scroll || (!scroll && textoBlanco)" class="fa fa-chevron-down mdc-text-white" aria-hidden="true"></i>
            </a>
        </div>
    </nav>
</header>

<div class="modal fade" id="pendingModalLong" tabindex="-1" role="dialog" aria-labelledby="pendingModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 col-md-5 align-self-center">
                        <img src="assets/img/pending/modal_pending.svg" alt="Pendiente pago" />
                    </div>
                    <div class="col-12 col-md-7 align-self-center">
                        <p class="titlePago">Estamos procesando la confirmación de tu pago.</p>
                        <p>Mientras tanto te invitamos a acceder a nuestra herramienta de <span>consulta
                                gratuita.</span></p>
                        <a href="https://www.midatacredito.com" rel="noopener" target="_self"
                            class="btn-midc btn-purple h-lg-btn w-100-btn" data-dismiss="modal" aria-label="Close">
                            <span>Entendido</span>
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<header class="only-logo" *ngIf="onlyLogo">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a href="/freemium/home" rel="noopener">
                    <img src="assets/img/logos/logo_midc.svg" alt="Midatacrédito" />
                </a>                
            </div>
        </div>
    </div>
</header>

<div class="col-12 new-header" [ngClass]="{'col-md-9' : !sesion.loggedIn, 'col-md-6' : sesion.loggedIn,'colorLog':sesion.loggedIn}"
    id="new-header">
    <div class="row">
        <div class="col-12 new-pages d-block d-md-none">
            <div class="row">
                <div class="col-md-6 col-12 content-pages ">
                    <div id="menu">
                        <!-- <div class="" [ngClass]="{'menu-item' : !sesion.loggedIn}">
                            <div id="soluciones" class="colorLogin">
                                <button class="new-title" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" (click)="clic_header_menu('Otras Soluciones')">
                                    Otras soluciones
                                </button>
                            </div>
                        
                            <div id="collapseOne" class="collapse" aria-labelledby="soluciones" data-parent="#menu">
                                <a class="menu-link" (click)="clic_header_menu('Datacrédito Empresas | Otras Soluciones')" href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank">
                                    Datacrédito Empresas
                                </a>
                                <a class="menu-link" (click)="redirectExterno(linkLogin,'login-iniciaSesion','HEADER1', true)" *ngIf="!sesion.loggedIn">
                                    Consultar mi Historia de crédito
                                </a>
                                <a routerLink="/landing/perfil-credito" class="menu-link" (click)="irOtrasSoluciones('Mejora mi puntaje de Datacrédito | Otras soluciones')" *ngIf="!sesion.loggedIn">
                                    Mejora mi Puntaje de Datacrédito
                                </a>
                                <a routerLink="/landing/protege" class="menu-link" (click)="irOtrasSoluciones('Protege mi presencia en internet | Otras soluciones')" *ngIf="!sesion.loggedIn">
                                    Protege mi Presencia en internet
                                </a>
                                <a routerLink="/landing/premium" class="menu-link" (click)="irOtrasSoluciones('Evitar el Fraude en mis compras | Otras soluciones')" *ngIf="!sesion.loggedIn">
                                    Evitar el Fraude en mis compras
                                </a>
                                <a routerLink="/comparacion-planes" class="menu-link" (click)="irOtrasSoluciones('Compara nuestros planes | Otras soluciones')" *ngIf="!sesion.loggedIn">
                                    Compara nuestros planes
                                </a>
                            </div>
                        </div> -->
                        <div [ngClass]="{'menu-item' : !sesion.loggedIn}">
                            <div id="ayudas" class="colorLogin">
                                <button (click)="clic_header_menu('Ayudas')" class="new-title" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Ayudas
                                </button>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="ayudas" data-parent="#menu">
                                <a class="menu-link" (click)="clic_header_menu('Datacrédito Empresas | Ayudas')" href="https://www.datacreditoempresas.com.co/?utm_source=web_datacredito_personas&utm_medium=link&utm_campaign=referido_datacredito_personas&utm_content=DataCredito_empresas_menu" rel="noopener" target="_blank">
                                    Datacrédito Empresas
                                </a>
                                <a class="menu-link" (click)="redirectExterno(linkLogin,'login-iniciaSesion','HEADER1', true)" *ngIf="!sesion.loggedIn">
                                    Consultar mi Historia de crédito
                                </a>
                                <a routerLink="/landing/perfil-credito" class="menu-link" (click)="irOtrasSoluciones('Mejora mi puntaje de Datacrédito | Ayudas')" *ngIf="!sesion.loggedIn">
                                    Mejora mi Puntaje de Datacrédito
                                </a>
                                <a routerLink="/landing/protege" class="menu-link" (click)="irOtrasSoluciones('Protege mi presencia en internet | Ayudas')" *ngIf="!sesion.loggedIn">
                                    Protege mi Presencia en internet
                                </a>
                                <a routerLink="/landing/premium" class="menu-link" (click)="irOtrasSoluciones('Evitar el Fraude en mis compras | Ayudas')" *ngIf="!sesion.loggedIn">
                                    Evitar el Fraude en mis compras
                                </a>
                                <a routerLink="/faq" class="menu-link" (click)="irOtrasSoluciones('Preguntas frecuentes | Ayudas')">
                                    Preguntas frecuentes
                                </a>
                                <a class="menu-link" (click)="clic_header_menu('Ley Habeas Data | Ayudas')" href="https://www.datacredito.com.co/habeas-data.jsp" rel="noopener" target="_blank">
                                    Ley Habeas Data
                                </a>
                                <a class="menu-link" (click)="irServicioCliente()">
                                    Contáctanos
                                </a>
                            </div>
                        </div>
                        <!-- <div [ngClass]="{'menu-item' : !sesion.loggedIn}" style="margin-bottom: 15px;">
                            <div class="colorLogin ml-2">
                                <a class="new-title-b" style="text-decoration: none;" (click)="clic_header_menu('Ponte al día')" (click)="closeMenu()" routerLink="/landing/pontealdia">
                                    Ponte al día
                                </a>                                
                            </div>
                        </div> -->
                        <div [ngClass]="{'menu-item' : !sesion.loggedIn}">
                            <div class="colorLogin ml-2">
                                <a class="new-title-b blog" (click)="clic_header_menu('Blog',true)" routerLink="/blog"  [queryParams]="{utm_source: 'MIDC', utm_medium: 'HEADERBLOG', utm_campaign: 'HOME'}">
                                    Blog
                                </a>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 content-pages"
                    [ngClass]="{'col-md-6' : !sesion.loggedIn, 'col-md-12' : sesion.loggedIn}">
                </div>
            </div>
        </div>
        <div class="col-12 new-services">
            <div class="row">
                <div class="col-12">
                    <div class="new-logo d-inline-block d-md-none">
                        <img src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito"/>
                    </div>
                    <button class="btnCerrar" (click)="openMenu()">
                        X
                    </button>
                </div>
                <div *ngIf="!sesion.loggedIn" class="col-12 new-content">
                    <a class="new-nav-link orange" data-idtag="HEADER5" data-nametag="registrar-registrate"
                        (click)="redirectExterno(linkLogin+'&utm_source=MIDC&utm_medium=HEADER&utm_campaign=HOME','login-iniciaSesion','HEADER1', true, true)"
                        rel="noopenner nofollow">Ingresar<i class="fa fa-chevron-right"></i></a>
                </div>
                
                <div *ngIf="sesion.loggedIn" class="col-12 new-content-logged">
                    <p class="new-tittle-w d-block d-md-none">{{ sesion.user.nombre | titlecase }}</p>
                    <div class="links-logged">
                        <a class="new-basic-link white" (click)="clic_header_menu('Mi perfil | Menú')" [href]="linkPerfil">
                            <img src="assets/img/icons/mi_cuenta.svg" alt="perfil Midatacrédito" title="Perfil Midatacrédito"/>
                            Mi perfil
                        </a>
                        <a class="new-basic-link white" (click)="irMiplan()">
                            <i class="fa fa-bookmark-o"></i>
                            Mi plan
                        </a>
                        
                        <a class="new-basic-link white" (click)="clic_header_menu('Mis autorizaciones | Menú')" [href]="linkAutorizaciones">
                            <img src="assets/img/header/Mis_autorizaciones_perfil_blanco.svg" alt="autorizaciones"/>
                            Mis autorizaciones
                        </a> 
                        <a class="new-basic-link white" (click)="irServicioCliente()">
                            <img src="assets/img/header/servicio-cliente.svg" alt="Servicio al cliente" title="Servicio al cliente"/>
                            Servicio al cliente
                        </a>
                        <!-- <a class="new-basic-link white" (click)="clic_header_menu('Mis autorizaciones | Menú')" [href]="linkAutorizaciones">
                            <img src="assets/img/header/Mis_autorizaciones_perfil_blanco.svg" />
                            Mis autorizaciones
                        </a>  -->
                        <div class="d-block d-md-none" >
                            <div *ngFor="let menu of sesion.dataMenu;let i = index">
                                <a class="new-basic-link white d-block d-md-none" *ngIf=" menu.subMenu.length == 0 && menu.type == 'local'" (click)="selectEvent(menu.function,menu.id)" [ngClass]="accessMenu(menu.typePlan) ? 'disabledGray' : ''">
                                    <img src="{{menu.icon}}" class="iconMenu" alt="{{menu.description}}" title="{{menu.description}}"/>
                                    <span>{{(menu.id != 4 ? menu.description : textNotification(i))}}</span>
                                    <img *ngIf="menu.new" class="iconNewItem" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad" title="Funciones midatacrédito"/>
                                    <img class="iconblock img-prem" *ngIf="accessMenu(menu.typePlan)" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="Nueva funcionalidad" title="Funciones midatacrédito"/>
                                </a>
                                <a class="new-basic-link white d-block d-md-none" *ngIf="menu.type == 'externo' && menu.cta != undefined" href="{{menu.cta}}" target="_blank" rel="noopener">
                                    <img *ngIf="menu.new" class="iconNewItem ext" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad" title="Funciones midatacrédito"/>
                                    <img src="{{menu.icon}}" class="iconMenuE" alt="{{menu.description}}"/>
                                </a>
                                <a class="new-basic-link white d-block d-md-none" *ngIf="menu.type == 'externo' && menu.cta == undefined" (click)="selectEvent(menu.function,menu.id)">
                                    <img *ngIf="menu.new" class="iconNewItem ext" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad" title="Funciones midatacrédito"/>
                                    <img src="{{menu.icon}}" class="iconMenuE" alt="{{menu.description}}" title="{{menu.description}}"/>
                                </a>
                                <div *ngIf=" menu.subMenu.length > 0">
                                    <a class="new-basic-link white d-block d-md-none" (click)="selectEvent(menu.function,menu.id)" [ngClass]="accessMenu(menu.typePlan) ? 'disabledGray' : ''">
                                        <img src="{{menu.icon}}" class="iconMenu" alt="{{menu.description}}" title="{{menu.description}}"/>
                                        <span>{{menu.description}}</span>
                                        <img class="iconblock img-prem" *ngIf="accessMenu(menu.typePlan)" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="Nueva funcionalidad" title="Funciones midatacrédito"/>
                                    </a>
                                    <div (click)="selectEvent(sub.function,menu.id)" class="box-link btn-main-menu" *ngFor="let sub of sesion.dataMenu[i].subMenu" [ngClass]="{'disabledGray': accessMenu(menu.typePlan),'d-none':!setAdvance(sub.function)}">
                                        <p class="new-basic-link white pLink"><span class="tSub">{{sub.description}}</span><img *ngIf="sub.new" class="iconNew" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad"/></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 new-content d-block d-md-none">
                    <a class="new-nav-link no-color" data-idtag="HEADER6" data-nametag="landing-deudas"
                    (click)="clic_header_menu('Negociar mis Obligaciones')" (click)="openMenu()" routerLink="/deudas" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERPAD','utm_campaign':'HOME'}"
                        rel="noopenner nofollow">Negociar mis obligaciones<i class="fa fa-chevron-right"></i></a>
                </div>
                <div class="col-12 new-content">
                    <a class="new-nav-link no-color d-block d-md-none" data-idtag="HEADER7" data-nametag="busacredito"
                    (click)="clic_header_menu('Buscar un Crédito')" [href]="linkBc+'?utm_source=MIDC&utm_medium=HEADERBC&utm_campaign=HOME'"
                        rel="noopenner nofollow">Buscar un Crédito<i class="fa fa-chevron-right"></i></a>
                </div>
                <div class="col-12 new-content">
                    <a class="new-nav-link no-color d-block d-md-none" data-idtag="HEADER8" data-nametag="busacredito"
                    (click)="clic_header_menu('Comparar Planes')" (click)="openMenu()"
                     routerLink="/comparacion-planes" [queryParams]="{'utm_source':'MIDC','utm_medium':'HEADERMIDC','utm_campaign':'HOME'}"
                        rel="noopenner nofollow">Comparar Planes<i class="fa fa-chevron-right"></i></a>
                </div>
                <div *ngIf="sesion.loggedIn" class="col-12 new-close-desk">
                    <button (click)="closeSession()">
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="sesion.loggedIn" class="col-12 new-close">
            <button (click)="closeSession()">
                Cerrar sesión
            </button>
        </div>
    </div>
</div>