import { Injectable } from '@angular/core';
import { BrokerService } from './broker.service';
import { ModalService } from './modal.service';
import { SesionService } from './sesion.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReclamacionService {

	paso1: boolean;
	paso2: boolean;
	paso3: boolean;

	cuenta: any;

	reclamo: any = null;
	code: any;
	novedatSession: any = null;
	novedatVisit: any = null;
	tiposReclamos: any;

  constructor(
    public broker: BrokerService,
    private modal: ModalService,
    public sesion: SesionService,
    private router: Router) {
    this.paso1 = true;
    this.paso2 = false;
	this.paso3 = false;
  }
	reclamoBase() {
		this.reclamo = {
			sessionId: null,
			accountE: null,
			accountType: null,
			idEntity: null,
			reclamo: {
				tipoLeyenda: '1',
				tipoReclamo: null,
        			subtipoReclamo: null,
				correoElectronico: null,
				recibirEMail: 's',
				numeroTelefono: null,
				direccionFisica: null,
				comentario: null,
			}
		};
	}
	public loadDispute = true;
	public errorDispute = false;
	crearReclamo() {
		this.loadDispute = false;
		this.errorDispute = false;
		this.sesion.spinnerDebt = true;  
		this.broker.CrearReclamacion(this.reclamo).subscribe((response: any) => {
			this.loadDispute = true;
			this.errorDispute = false;
			this.sesion.spinnerDebt = false;   
			[
				this.sesion.messCheck = true, 
				this.sesion.messBg = 'check',
				this.sesion.messTitle = this.sesion.messageAlerts[2].reclamations?.[0].text,
				this.sesion.messDescription = this.sesion.messageAlerts[2].reclamations?.[0].description,
				this.sesion.messIcon = 'fa-smile-o',
				this.sesion.messColorTxt = 'check-txt'
			];
			this.modal.modalOkReclamacion();
			if(this.sesion.estadoUserF == 'ACTIVO' || this.sesion.estadoUserF == 'ACTIVE_PROCESS'){
				this.router.navigate(['/private/historico']);
			}else{
				this.router.navigate(['/freemium/historico']);
			}
		}, (error) => {
			this.loadDispute = true;
			this.errorDispute = true;
			this.sesion.spinnerDebt = false;      
			switch ( error.status ) {
				case 200:
					[
						this.sesion.messCheck = true, 
						this.sesion.messBg = 'check',
						this.sesion.messTitle = this.sesion.messageAlerts[2].reclamations?.[1].text,
						this.sesion.messDescription = this.sesion.messageAlerts[2].reclamations?.[1].description,
						this.sesion.messIcon = 'fa-smile-o',
						this.sesion.messColorTxt = 'check-txt'
					]
					break;
				case 500:
					this.sesion.spinnerDebt = false;   
					[
						this.sesion.messError = true, 
						this.sesion.messBg = 'error',
						this.sesion.messTitle = this.sesion.messageAlerts[2].reclamations?.[2].text,
						this.sesion.messDescription = this.sesion.messageAlerts[2].reclamations?.[2].description,
						this.sesion.messIcon = 'fa-meh-o',
						this.sesion.messColorTxt = 'error-txt'
					];  
					// if(this.sesion.estadoUserF == 'ACTIVO'){
					// 	this.router.navigate(['/private/historico']);
					// }else{
					// 	this.router.navigate(['/freemium/historico']);
					// }
				break;
				default:
					this.sesion.spinnerDebt = false;   
					[
						this.sesion.messError = true, 
						this.sesion.messBg = 'error',
						this.sesion.messTitle = this.sesion.messageAlerts[2].reclamations?.[2].text,
						this.sesion.messDescription = this.sesion.messageAlerts[2].reclamations?.[2].description,
						this.sesion.messIcon = 'fa-meh-o',
						this.sesion.messColorTxt = 'error-txt'
					]; 
				break;
			}
		});
	}
}
