<footer [ngClass]="verifyUrl()" class="col-12">
    <div class="row custom-container">
        <div class="col-12 col-md-5">
            <div class="footer-search">
                <div class="logo-footer">
                    <img class="border-r" src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo midatacrédito" appLazyLoadImg>
                    <a (click)="clic_footer_download_app()" href="https://play.google.com/store/apps/details?id=com.midatacredito">
                        <img src="assets/img/home/Logo_PlayStore_Blanco.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo midatacrédito">
                    </a>
                </div>
                <div class="imgs-pays d-none d-md-block" *ngIf="footerPay appLazyLoadImg">
                    <img src="assets/img/logos/Visa_Logo_Blanco.svg" alt="Logo visa" title="Visa" appLazyLoadImg/>
                    <img src="assets/img/logos/Mastercard_Logo_Blanco.svg" alt="Logo Mastercard" title="Mastercard"  appLazyLoadImg/>
                    <img src="assets/img/logos/AMEX_Logo_Blanco.svg" alt="Logo American express" title="American Express" appLazyLoadImg/>
                    <img src="assets/img/logos/Diners_Club_Logo_Blanco.svg" alt="Logo diners club" title="Dinners Club" appLazyLoadImg/>
                    <img src="assets/img/logos/PSE_Logo_Blanco.svg" alt="Logo PSE" title="PSE" appLazyLoadImg/>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2">
            <div class="footer-links" *ngIf="!footerPay">
                <div class="head-links" (click)="showBodylinks(0)">
                    <p>Nuestras soluciones</p>
                    <i class="fa fa-plus"></i>
                </div>
                <div class="body-links">
                    <div class="box-link" (click)="clic_footer_menu('Buscacredito')">
                        <a href="{{linkDc}}" rel="noopener" target="_blank">Buscacrédito</a>
                    </div>
                    <!-- <div class="box-link">
                        <a (click)="clic_footer_menu('OneShot', 'https://usuario.midatacredito.com/oneshot?product=os')">One Shot</a>
                    </div> -->
                    <div class="box-link" (click)="clic_footer_menu('PonteAlDía')">
                        <a href="https://pontealdia.midatacredito.com/?UTM_CAMPAIGN=MiDCReferral&UTM_SOURCE=MenuQN/" rel="noopener" target="_blank">Ponte al día</a>
                    </div>
                    <div class="box-link" (click)="clic_footer_menu('Verifica')">
                        <a href="https://www.midatacredito.com/verifica/?UTM_CAMPAIGN=MiDCReferral&UTM_SOURCE=MenuQN" rel="noopener" target="_blank">Verifica</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2">
            <div class="footer-links">
                <div class="head-links" (click)="showBodylinks(1)">
                    <p>Legal</p>
                    <i class="fa fa-plus"></i>
                </div>
                <div class="body-links">
                    <div class="box-link" (click)="clic_footer_menu('Política de privacidad')">
                        <a href="{{url_politica_privacidad}}" target="_blank">Política de privacidad</a>
                    </div>
                    <div class="box-link" (click)="clic_footer_menu('Términos y condiciones')">
                        <a href="{{url_terminos_condiciones}}" target="_blank">Términos y condiciones</a>
                    </div>
                    <div class="box-link" *ngIf="!footerPay" (click)="clic_footer_menu('Superintendencia de Industria y Comercio - SIC')">
                        <a href="https://www.sic.gov.co/" rel="noopener" target="_blank">Superintendencia de Industria y Comercio – SIC</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2">
            <div class="footer-links">
                <div class="head-links" (click)="showBodylinks(2)">
                    <p>Contáctanos</p>
                    <i class="fa fa-plus"></i>
                </div>
                <div class="body-links">
                    <div class="box-link" (click)="clic_footer_menu('Preguntas frecuentes')">
                        <a routerLink="/faq" target="_self">Preguntas frecuentes</a>
                    </div>
                    <div class="box-link" (click)="irServicioCliente()" (click)="clic_footer_menu('Servicio al cliente')">
                        <a target="_self">Servicio al cliente</a>
                    </div>
                    <div class="box-link" (click)="clic_footer_menu('Blog')">
                        <a routerLink="/blog" rel="noopener">Blog</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-block d-md-none border-top" *ngIf="footerPay">
            <div class="imgs-pays">
                <img src="assets/img/logos/Visa_Logo_Blanco.svg" alt="Logo visa" title="Visa" appLazyLoadImg/>
                <img src="assets/img/logos/Mastercard_Logo_Blanco.svg" alt="Logo Mastercard" title="Mastercard" appLazyLoadImg/>
                <img src="assets/img/logos/AMEX_Logo_Blanco.svg" alt="Logo American Express" title="American Express" appLazyLoadImg/>
                <img src="assets/img/logos/Diners_Club_Logo_Blanco.svg" alt="Logo Diners club" title="Dinners Club" appLazyLoadImg/>
                <img src="assets/img/logos/PSE_Logo_Blanco.svg" alt="Logo PSE" appLazyLoadImg/>
            </div>
        </div>
        <hr/>
    </div>
    <div class="row custom-container align-items-center">
        <div class="col-md-6 col-12 order-md-1 order-2">
            <div class="copyright">
                <p>{{anio}} <b>Experian Colombia S.A.</b> © Derechos reservados.<br/> 
                    NIT 900.422.614-8.<br/> 
                    Carrera 7 No. 76 – 35 Bogotá D.C., Colombia.<br/> 
                    Email: servicioalciudadano&#64;experian.com</p>
            </div>
        </div>
        <div class="col-md-6 col-12 order-md-2 order-1">
            <div class="copyright">
                <div class="images-copyright">
                    <img src="assets/img/logos/logo-copy.svg" alt="Datacrédito Experian" title="Datacrédito Experian">
                    <p class="sic">VIGILADO</p>
                    <img src="assets/img/logos/logo_SIC_blanco.png" alt="Superintendencia de industria y comercio" title="Logo Superintendencia de industria y comercio">
                    <img style="width: 50px;" src="assets/img/logos/iq_blanco.png" alt="Iq seguridad en pagos digitales" title="Iq seguridad en pagos digitales">
                </div>
            </div>
        </div>
        <p class="version">
            V.a13-4.2
        </p>
    </div>
    <div class="row custom-container disclaminer" *ngIf="showDisclaimer">
        <hr/>
        <div class="col-12">
            <p>&#185; Impacto de Midatacrédito en la vida financiera de los Colombianos. Muestra estadística de <b>más de 5.000</b> usuarios activos en el portal que respondieron la encuesta.</p>
            <p>&#178; Todas las funcionalidades expuestas en esa sección, son <b>funcionalidades</b> son <b>pagas</b> y las puedes adquirir comprando una <b>suscripción</b> desde el portal web.</p>
            <p>&#179; Experian <b>no origina créditos</b> ni asume <b>responsabilidad</b> por la aprobación o rechazo de créditos ofrecidos por entidades financieras y Fintech.</p>
        </div>
    </div>
</footer>